<template>
    <span class="checkboxImg wms-node-container">
        <div class="wms-node-title">
            <button
                v-if="!hasParent && !hasChildren"
                class="expandToggleBtn"
                @click="toggleLegendExpanded"
            >
                <img
                    v-if="legendExpanded"
                    class="expanded"
                    src="../../../assets/minus.svg"
                />
                <img
                    v-else
                    class="collapsed"
                    src="../../../assets/plus_sign.svg"
                />
            </button>
            <div class="entryLabel-wrapper d-flex">
                <input
                    type="checkbox"
                    class="checkBox"
                    v-model="layer.isChecked"
                    @change="
                        updateIsChecked({
                            id: layer.id,
                            visibility: 'noChange',
                        })
                    "
                    :disabled="
                        (layer.maxZoom < mapData.mapZoom ||
                            layer.minZoom > mapData.mapZoom) &&
                            layer.children.length == 0
                    "
                />
                <span v-if="showLabel" class="entryLabel" :title="layer.name">{{
                    layer.name
                }}</span>
            </div>
        </div>
        <div v-if="layer.children.length == 0" class="imgDiv">
            <img v-show="legendExpanded" :src="layer.legend" />
        </div>
    </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
    name: 'imgNode',
    props: {
        // want the object with the data that is being rendered
        id: {
            required: true,
            type: Number,
        },
        // whether or not to show the label for an item, can set false to hide labels
        showLabel: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            legendExpanded: null,
        };
    },
    methods: {
        ...mapMutations('emergencyManagement', ['updateIsChecked']),
        // loads the image
        //  https://stackoverflow.com/a/47480286/8061390
        toggleLegendExpanded() {
            this.legendExpanded = !this.legendExpanded;
        },
    },
    computed: {
        ...mapState('projects', ['mapData']),
        ...mapState('emergencyManagement', ['layersStruct']),
        layer() {
            if (this.layersStruct && this.layersStruct[this.id]) {
                return this.layersStruct[this.id];
            } else {
                return {};
            }
        },
        hasParent() {
            return this.layer.parentID != 0;
        },
        hasChildren() {
            try {
                return Boolean(this.layer.children.length);
            } catch (e) {
                return false;
            }
        },
    },
    created() {
        // layers that do not have parents should be collapsed by default
        this.legendExpanded = this.hasParent;
    },
};
</script>

<style>
.imgDiv {
    padding-left: 62px;
}
.child .imgDiv {
    padding-left: 32px !important;
}

.entryLabel {
    padding-left: 10px;
    vertical-align: top;
    text-align: left;
    font: normal normal 500 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #212121;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    margin-top: auto;
    margin-bottom: auto;
}
.checkboxImg {
    padding-top: 4px;
}
input.checkBox[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    /* border: 122px solid black !important;
    border-radius: 2px; */
    opacity: 1;
    accent-color: black;
    margin-top: 2px;
}

.expandToggleBtn img {
    position: relative;

    width: 1rem;
    height: 1rem;
}

.wms-node-container {
    width: 100%;
}
.wms-node-container .entryLabel {
    vertical-align: super;
}
.wms-node-title {
    display: flex;
    flex-flow: row wrap;
}

@media only screen and (max-width: 1800px) {
    .wms-node-container .entryLabel {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
</style>
