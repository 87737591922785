<template>
    <div>
        <ol class="wmsLayers-list-container">
            <li v-if="treeLevel == 1">
                <b-dropdown id="dropdown-text" :text="treeFilter" class="m-2">
                    <b-dropdown-item-button
                        v-for="(litem, lidx) in treeChildrenOne(
                            this.treeFilter
                        )"
                        :key="lidx"
                        @click="onClick(litem)"
                    >
                        {{ litem.name }}
                    </b-dropdown-item-button>
                </b-dropdown>
            </li>
            <li v-else>
                <b-dropdown
                    id="dropdown-grouped"
                    :text="treeFilter"
                    class="m-2"
                >
                    <b-dropdown-group
                        v-for="(item, idx) in treeChildren(this.treeFilter)"
                        :key="idx"
                        :header="item.name"
                        :id="item.id.toString()"
                    >
                        <b-dropdown-item-button
                            v-for="(secondItem, sidx) in treeChildren(
                                item.name
                            )"
                            :key="sidx"
                            @click="onClick(secondItem)"
                        >
                            {{ secondItem.name }}
                        </b-dropdown-item-button>
                    </b-dropdown-group>
                </b-dropdown>
            </li>
        </ol>
        <div v-if="selectedName !== null">
            Selected Layer: {{ this.selectedName }}
            <ol class="wmsLayers-list-container">
                <li
                    v-for="(item, idx) in treeChildren(this.selectedName)"
                    :key="idx"
                >
                    <div class="layer-group-container layer-group-wms">
                        <img-node :id="item.id" :zoomLevel="zoomLevel" />
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import imgNode from '../EmergencyManagement/TableOfContents/imgNode.vue';

export default {
    name: 'LayerSelection',
    components: { imgNode },
    props: {
        treeParent: {
            required: false,
            type: Number,
            default: 0,
        },
        zoomLevel: {
            type: Number,
            required: true,
            default: 0,
        },
        treeFilter: {
            type: String,
            default: '',
        },
        treeLevel: {
            type: Number,
            default: 1,
        },
        selectDefault: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selectedId: null,
            selectedName: null,
        };
    },
    mounted: function() {
        if (this.selectedName == null && this.selectDefault != '') {
            this.selectedName = this.selectDefault;
        }
    },
    beforeDestroy() {
        this.treeChildren(this.selectedName).forEach(checkClear);
        function checkClear(item) {
            item.isChecked = false;
        }
    },
    methods: {
        ...mapMutations('emergencyManagement', ['toggleExpanded']),
        logMessage() {},
        checked(e) {},
        treeChildren(filterName) {
            var parent = this.layersStruct.find(
                (treeItem) => treeItem.name == filterName
            );

            var layers = [];
            if (parent) {
                const childNode = this.layersStruct.filter(
                    (child) => child.parentID === parent.id
                );
                if (childNode) {
                    layers = childNode;
                }
            }

            return layers.sort(
                (a, b) =>
                    a.toc_groupOrder - b.toc_groupOrder ||
                    a.toc_layerOrder - b.toc_layerOrder ||
                    a.name.localeCompare(b.name)
            );
        },
        treeChildrenOne(filterName) {
            var parent = this.layersStruct.find(
                (treeItem) => treeItem.name == filterName
            );
            var layers = [];
            if (parent) {
                const childNode = this.layersStruct.filter(
                    (child) =>
                        child.parentID === parent.id &&
                        child.children.length > 0
                );

                for (const item of childNode) {
                    const grandchildNode = this.layersStruct.find(
                        (child) => child.parentID === item.id
                    );

                    if (grandchildNode.children.length == 0) {
                        layers.push(item);
                    }
                }
            }

            return layers.sort(
                (a, b) =>
                    a.toc_groupOrder - b.toc_groupOrder ||
                    a.toc_layerOrder - b.toc_layerOrder ||
                    a.name.localeCompare(b.name)
            );
        },
        onClick(e) {
            this.selectedId = e.id;
            this.selectedName = e.name;
        },
    },
    watch: {
        selectedName(newSelectedName, oldSelectedName) {
            this.treeChildren(oldSelectedName).forEach(checkClear);
            function checkClear(item) {
                item.isChecked = false;
            }
            this.treeChildren(newSelectedName).forEach(checkSelect);
            function checkSelect(item) {
                item.isChecked = true;
            }
        },
    },
    computed: {
        ...mapState('emergencyManagement', {
            layersStruct: 'layersStructValues',
        }),
        ...mapGetters('dreams', {
            dreamsModel: 'model',
        }),
    },
};
</script>

<style>
ul {
    list-style-type: none;
}
.parent {
    left: -5px;
}
.parentExpandCollapse {
    left: 0 !important;
}
.expand-collapse {
    max-width: 220px;
    align-self: center;
}
.tocList {
    padding-left: 15px;
}
.layer-group-wms {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 36px;
    padding-bottom: 5px;
}
.check-box::before {
    content: '\2611';
    color: dodgerblue;
}
.expandToggleBtn {
    width: 21px;
    height: 21px;
    padding: 2px 2px 2px 2px;
    align-items: center;
    justify-items: center;
    border-radius: 4px;
    background-color: white;
    border: none;
    margin-right: 8px;
}
.expandToggleBtn img {
    width: 17.3px;
    height: 17.3px;
}
.layer-group-container {
    padding: 7px 0 7px 0;
}
.dashed-line {
    border-top: 1px dashed #d1d1d1;
}
.solid-line {
    border-top: 1px solid #d1d1d1;
}
.control_wrapper {
    min-width: 315px;
}
.sideBarBody .control_wrapper {
    max-width: 95% !important;
}
.wmsLayers-list-container {
    padding-left: 7px;
}
.child {
    margin-left: 40px;
}
</style>
