import { request } from './request';

export const dreamsService = {
    getManholeModel,
    UploadRiskModel,
    UpdateRiskModel,
    ResetRiskModel,
    LoadRiskModel,
    GetLinksInPolygon,    
    UploadHydraulicsModel,
    ShapeFileToGeoJson,
    LoadSewer,
    GetLatestVersion,
    DownloadRiskModel,
    ExportShape,
    TaskStatus,
};

function getManholeModel(projectId) {
    let projectDataLayers =[];
    return new Promise((resolve, reject) => {
        resolve(projectDataLayers);
      });
}


function UploadRiskModel(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'upload_risk_zip',
        data: data,
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
    });
}

function UpdateRiskModel(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'update_risk_model',
        data: data,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
    });
}

function ResetRiskModel(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'reset_risk_model',
        data: data,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
    });
}

function LoadRiskModel(userId, projectId) {
    let params = "?user_id="+userId+"&project_id="+projectId;
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'load_risk_model' + params,
        method: 'gett',
        headers: {'Content-Type': 'application/json'},
    });
}

function GetLinksInPolygon(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'get_links_in_polygon',
        data: data,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
    });
}

function UploadHydraulicsModel(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'upload_shapefile',
        data: data,
        method: 'post',        
        headers: {'Content-Type': 'application/json'},
    });
}

function ShapeFileToGeoJson(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'upload_shapefile',
        data: data,
        method: 'post',        
        headers: {'Content-Type': 'application/json'},
    });
}

function LoadSewer(owner) {
    let params = "?owner="+owner;
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'load_lacsd_local_lines' + params,
        method: 'get',
        headers: {'Content-Type': 'application/json'},
    });
}

function GetLatestVersion(userId, projectId) {
    let params = "?user_id="+userId+"&proj_id="+projectId;
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'get_latest_version' + params,
        method: 'get',
        headers: {'Content-Type': 'application/json'},
    });
}

function DownloadRiskModel(userId, projectId) {
    let params = "?user_id="+userId+"&proj_id="+projectId;
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'export_risk_model_to_shp' + params,
        method: 'get',
        headers: {'Content-Type': 'application/json'},
    });
}

function ExportShape(userId, projectId) {
    let filename =`${projectId}-${userId}.zip`;
    let params = "?container_name=exports&blob_name="+filename;
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'download_risk_model_shp/'+params,
        method: 'get',
    });
}

function TaskStatus(data) {
    return request({
        url: process.env.VUE_APP_DREAMS_API_URI + 'tasks/'+data,
        method: 'get',
    });
}