<template>
    <div class="projectLayers-container">
        <div class="header-container">
            <ul id="project-layers" class="tocList layers-container">
                <li>
                    <layer-selection
                        :zoomLevel="zoomLevel"
                        treeFilter="Links Model"
                        :treeLevel="2"
                    />
                </li>
            </ul>

            <div v-if="isSecondaryModel()">
                <h5 class="trans-sm">Opacity</h5>
                <div class="d-flex my-4">
                    <span class="font-weight-bold indigo-text mr-2 mt-1"
                        >0</span
                    >
                    <form class="grey-color">
                        <input
                            type="range"
                            v-model="transparencyValue"
                            min="0"
                            step="1"
                            max="100"
                        />
                    </form>
                    <span class="font-weight-bold indigo-text ml-2 mt-1"
                        >100</span
                    >
                    <p margin-top="50px">
                        <input
                            type="text"
                            margin-top="50px"
                            class="input-value"
                            display="inline-block"
                            min="0"
                            max="100"
                            maxlength="3"
                            size="2.5ch"
                            padding-top="5px"
                            v-model.lazy="transparencyValue"
                        />%
                    </p>
                </div>
            </div>
        </div>
        <div class="content-container">
            <b-button class="buttonSelect map-btn m-1 " @click="openModelB">
                Model B</b-button
            >
        </div>

        <div class="footer-container">
            <div>
                <b-form-file
                    accept=".zip"
                    v-model="file"
                    class="upload-formlabel"
                    id="newHydraulicsModelUpload"
                    ref="newHydraulicsModelUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="hydraulicsModelFileValidation()"
                ></b-form-file>
            </div>
            <div>
                <b-button
                    class="buttonSelect map-btn m-1 "
                    @click="uploadHydraulicsModel(file)"
                >
                    Upload Base Model
                </b-button>
                <!-- <b-button
                    class="buttonSelect map-btn m-1 "
                    @click="loadHydraulicsModel()"
                >
                    Load/Reset
                </b-button> -->
            </div>
            <div>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="uploadSecondaryModel(file)"
                >
                    Upload Secondary Model
                </b-button>
                <!-- <b-button
                    class="buttonSelect map-btn m-1"
                    @click="resetHydraulicsModel()"
                >
                    Reset
                </b-button> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import { dreamsFunctions } from '../../utilities/dreamsFunctions';
import { dreamsService } from '../../services/dreams.service';
import LayerSelection from './LayerSelection.vue';

export default {
    name: 'HydraulicsModel',
    components: {
        LayerSelection,
    },
    data() {
        return {
            file: null,
            zoomLevel: 5,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            transparencyValue: 100,
            columnValue: 'ds_FLOW',
            loading: false,
            intervalQueue: [null, null],
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        ...mapActions('dreams', [
            'setModel',
            'setHydraulicsBase',
            'setHydraulicsSecondary',
            'clearHydraulicsBase',
            'clearHydraulicsSecondary',
            'setColorRange',
            'clearColorRange',
            'setShowModel',
        ]),
        hydraulicsModelFileValidation() {
            var fileInput = document.getElementById('newHydraulicsModelUpload');
            var filePath = fileInput.value;
            var allowedExtensions = /\.zip$/i;
            var extensionCheck = allowedExtensions.exec(filePath);

            if (!extensionCheck) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage: 'Invalid file type',
                });
                fileInput.value = '';
                return false;
            }
        },
        uploadHydraulicsModel(file) {
            const queueService = 'Upload main zipped shape file';
            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });
                let data = new FormData();
                data.append('shapefile', file);
                data.append('table_name_prefix', 'model_a_');
                data.append('table_name_suffix', '_');

                dreamsService
                    .UploadHydraulicsModel(data)
                    .then((init_response) => {
                        setTimeout(() => {
                            this.setToastMessage({
                                queueService: queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                downloadLink: '',
                                visible: true,
                            });
                        }, '1000');

                        const taskId = init_response.data.task_id;
                        this.$refs['newHydraulicsModelUpload'].reset();
                        this.startStatusCheckInterval(taskId, queueService, 0); //0:uploadHydraulicsModel
                    });
            } catch (error) {
                this.$refs['newHydraulicsModelUpload'].reset();
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        uploadSecondaryModel(file) {
            const queueService = 'Upload secondary zipped shape file';
            try {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'start',
                    queueMessage: 'started.',
                    downloadLink: '',
                    visible: true,
                });
                let data = new FormData();
                data.append('shapefile', file);
                data.append('table_name_prefix', 'model_b_');
                data.append('table_name_suffix', '_');

                dreamsService
                    .UploadHydraulicsModel(data)
                    .then((init_response) => {
                        setTimeout(() => {
                            this.setToastMessage({
                                queueService: queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                downloadLink: '',
                                visible: true,
                            });
                        }, '1000');

                        const taskId = init_response.data.task_id;
                        this.$refs['newHydraulicsModelUpload'].reset();
                        this.startStatusCheckInterval(taskId, queueService, 1); //1:uploadSecondaryModel
                    });
            } catch (error) {
                this.$refs['newHydraulicsModelUpload'].reset();
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        resetHydraulicsModel() {
            this.loading = true;
            dreamsFunctions.clearMap();
            this.setHydraulicsBase();
            this.setHydraulicsSecondary();
            this.loading = false;
        },
        getColumnAttributes() {
            let attributes = [];
            if (this.hydraulicsBase.Links !== undefined) {
                //numeric attribute will be parameter option
                for (const [key, value] of Object.entries(
                    this.hydraulicsBase.Links.features[0].popupContent
                )) {
                    if (!isNaN(value)) {
                        attributes.push(key);
                    }
                }
            }
        },
        updateColorRange() {
            this.loading = true;
            let range = dreamsFunctions.getColorRange(
                this.hydraulicsBase,
                this.columnValue
            );
            this.setColorRange(range);
            dreamsFunctions.getHydraulicsBase(
                this.hydraulicsBase,
                this.columnValue,
                range
            );
            if (dreamsFunctions.hasLineLayer(this.hydraulicsSecondary)) {
                range = dreamsFunctions.getColorRange(
                    this.hydraulicsSecondary,
                    this.columnValue
                );
                dreamsFunctions.getHydraulicsSecondary(
                    this.hydraulicsSecondary,
                    this.columnValue,
                    range,
                    this.transparencyValue
                );
            }
            this.loading = false;
        },
        isSecondaryModel() {
            return dreamsFunctions.hasLineLayer(this.hydraulicsSecondary);
        },
        openModelB() {
            this.setShowModel(true);
        },
        async startStatusCheckInterval(
            taskId,
            queueService,
            queueId,
            showSuccess = true
        ) {
            this.intervalQueue[queueId] = setInterval(async () => {
                try {
                    const status_response = await dreamsService.TaskStatus(
                        taskId
                    );
                    const taskStatus = status_response.data.task_status;
                    let taskResult = status_response.data.task_result;
                    if (taskStatus == 'SUCCESS') {
                        taskResult = JSON.parse(taskResult);
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'complete',
                            queueMessage: 'completed.',
                            downloadLink: '',
                            visible: showSuccess,
                        });
                        clearInterval(this.intervalQueue[queueId]);
                    } else if (taskStatus == 'FAILURE') {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: taskResult,
                            downloadLink: '',
                            visible: true,
                        });
                        clearInterval(this.intervalQueue[queueId]);
                    }
                } catch (error) {
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'error',
                        queueMessage: error,
                        downloadLink: '',
                        visible: true,
                    });
                }
            }, 5000);
        },
    },

    created() {
        this.setModel('Hydraulics');
        this.clearColorRange();
    },
    mounted() {},

    deactivated() {
        this.clearColorRange();
        this.setShowModel(false);
    },
    beforeDestroy() {
        this.setShowModel(false);
    },
    watch: {
        transparencyValue(newTransparencyValue, oldTransparencyValue) {
            this.updateColorRange();
        },
        columnValue(newColumnValue, oldColumnValue) {
            this.updateColorRange();
        },
    },
    computed: {
        ...mapGetters('projectLayers', { projectLyrs: 'projectLyrs' }),
        ...mapState('dreams', {
            hydraulicsBase: (state) => state.dreams.HydraulicsBase,
            hydraulicsSecondary: (state) => state.dreams.HydraulicsSecondary,
        }),
    },
};
</script>
<style lang="scss" scoped>
.projectLayers-container {
    position: relative;
    float: left;
    height: calc(100vh - 126px);
    width: 100%;
    overflow: hidden;
    direction: ltr !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.layers-container {
    padding: 0 27px 0 19px;
    margin-top: 5px;
}
.header-container {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100vh - 224px);
    overflow-y: scroll;
}
.content-container {
    position: relative;
    float: left;
    width: 100%;
    padding: 10px;
}
.footer-container {
    align-self: flex-end;
    float: right;
    width: 100%;
    min-height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    text-align: center;
    justify-content: space-between;
    padding: 10px;
}
.upload-formlabel {
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    color: #626262 !important;
    height: 100%;
    line-height: 2;
    font-family: var(--ffr);
    font-size: 16px;
    font-weight: normal;
    padding-left: 18px;
    text-align: left;
}
</style>
